const Data = [
  {
    header: 'Scheduling',
    position: 1,
    block: 'gray'
  },
  {
    header: 'Analytics',
    position: 2,
    block: 'purple'
  },
  {
    header: 'Live chat escalation',
    position: 3,
    block: 'orange'
  },
  {
    header: 'Personalized notifications',
    position: 4,
    block: 'green'
  },
  {
    header: 'Ticketing escalation',
    position: 5,
    block: 'blue'
  },
  {
    header: 'Assisted browsing to products and services',
    position: 6,
    block: 'red'
  },
  {
    header: 'Interviews, Polls, Surveys',
    position: 7,
    block: 'yellow'
  },
  {
    header: 'Automated answers',
    position: 8,
    block: 'turquoise'
  }
]

export default Data
