import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FaBars } from 'react-icons/fa'

import PropTypes from 'prop-types'
import { Container } from '../../utils/grid-system'
import logo from '../../assets/images/Logo.svg'

import ScrollspyMenu from './utility/ScrollspyMenu'

import { debounce } from '../../utils'

import NavigationWrap from './navigation.style'

// eslint-disable-next-line no-undef
const windowGlobal = typeof window !== 'undefined' && window

const Navigation = ({ menuItems, logoLink }) => {
  const [expand, setExpand] = useState(false)
  const [scroll, setScroll] = useState(false)

  const scrollOffset = () => {
    if (windowGlobal.pageYOffset > 50) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useEffect(() => {
      // eslint-disable-next-line no-undef
      windowGlobal.addEventListener('scroll', () => debounce(scrollOffset(), 100))
      // Remove the event listener
      return(() => {
        windowGlobal.removeEventListener('scroll', () => scrollOffset())
      })
  }, [])
  return (
    <NavigationWrap className={`nav-block ${scroll ? 'scrolled' : ''}`}>
      <Container fluidMD>
        <div className="navbar-wrap">
          <Link to={logoLink} className="logo">
            <img src={logo} alt="Helvia Technologies Logo" />
          </Link>
          <nav className="nav">
            <FaBars
              className="mobile-menu-icon"
              onClick={() => setExpand(!expand)}
            />
            <ScrollspyMenu
              className={`collapsed ${expand ? 'is-expanded' : ''}`}
              menuItems={menuItems}
            />
          </nav>
        </div>
      </Container>
    </NavigationWrap>
  )
}

Navigation.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  logoLink: PropTypes.string
}
Navigation.defaultProps = {
  menuItems: null,
  logoLink: ''
}
export default Navigation
