/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/layout/layout';
import Banner from '../components/Banner-modern';
import { Container, Row, Col, Section } from '../utils/grid-system';
import Nav from '../components/Navigation';
import NavigationData from '../assets/content/navigation';
import SolutionsData from '../assets/content/solutions';
import AutomationsData from '../assets/content/automations';
import BubbleSpeach from '../components/BubbleSpeach';
import SolutionCard from '../components/SolutionCard';
import Automations from '../components/Automations';
import Integrations from '../components/Integrations';
import ChooseUs from '../components/ChooseUs/chooseUs.style';
import TrustedBy from '../components/TrustedBy';
import SEO from '../components/SEO';
import CookieConsent from 'react-cookie-consent';
// import logo from '../../assets/images/Logo.svg'

import bannerImage from '../assets/images/headerBanner/3d_guy.png'
import bannerData from '../assets/content/banner'

import { Breakpoints } from '../utils/grid-system/Breakpoints'

const StyledIntegrationsContainer = styled.div`
  margin-top: 0;
  @media only screen and (min-width: ${Breakpoints.xl}) {
    margin-top: 140px;
  }
`
const IndexPage = () => {
  const chooseUs = {
    background: 'rgba(185, 226, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center'
  }
  return (
    <Layout>
      <SEO
        title="Helvia Technologies | Experience Management with Bots"
        structuredData={`
          "@context": "http://schema.org",
          "@type": "Organization",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Athens, Greece",
            "postalCode": "11854",
            "streetAddress": "Evristheos 2"
          },
          "description": "Helvia mission is to deliver the go-to next-generation Experience Management (XM) platform, using artificial intelligence (AI) and conversational bots.",
          "name": "Helvia Technologies"
        `}
        lang="en"
      />
      <Banner
        bold={bannerData.bold}
        title={bannerData.title}
        accent={bannerData.accent}
        paragraph={bannerData.subtitle}
        image={bannerImage}
        navbar={<Nav menuItems={NavigationData} logoLink="/" />}
      />
      <Container>
        <Section id="solutions">
          <Row>
            <Col>
              <BubbleSpeach
                variant="bubbleLeft"
                content="Wait, what kind of automation is this?"
              />
            </Col>
            <Col>
              <BubbleSpeach
                variant="bubbleRight"
                maxWidth="360px"
                backgroundColor={(props) => props.theme.greenLight2}
                content="It is communication automation with bots!"
              />
            </Col>
          </Row>
          {/* Solutions Start */}
          <Row>
            {SolutionsData && SolutionsData.length > 0
              ? SolutionsData.map((solution) => (
                <Col display="flex" md={4} key={solution.header}>
                  <SolutionCard
                    background={solution.background}
                    icon={solution.icon}
                    iconBackground={solution.iconBackground}
                    header={solution.header}
                    headerColor={solution.headerColor}
                    bodyBefore={solution.bodyBefore}
                    value={solution.value}
                    valueColor={solution.valueColor}
                    valueBackgroundColor={solution.valueBackgroundColor}
                    valueShadow={solution.valueShadow}
                    bodyAfter={solution.bodyAfter}
                  />
                </Col>
              ))
              : null}
          </Row>
          {/* Solutions End */}
        </Section>
        <Section id="automations">
          <Row>
            <Col>
              <BubbleSpeach
                variant="bubbleLeft"
                content="Hmm... and how do you make such amazing stuff happen?"
              />
            </Col>
            <Col>
              <BubbleSpeach
                variant="bubbleRight"
                maxWidth="360px"
                backgroundColor={(props) => props.theme.greenLight2}
                content="Well, our bots actually do the work for you!"
              />
            </Col>
          </Row>
          {/* Automations Start */}
          <Row>
            <Automations content={AutomationsData} />
          </Row>
          {/* Automations End */}
        </Section>
        <Section>
          <StyledIntegrationsContainer>
            <Row>
              <Col>
                <BubbleSpeach
                  variant="bubbleLeft"
                  content="Cool! Does it work on my system?"
                />
              </Col>
              <Col>
                <BubbleSpeach
                  variant="bubbleRight"
                  maxWidth="360px"
                  backgroundColor={(props) => props.theme.greenLight2}
                  content="Of course! We bring our bots where you are!"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Integrations />
              </Col>
            </Row>
          </StyledIntegrationsContainer>
        </Section>
        <Section>
          <Row>
            <Col>
              <BubbleSpeach
                variant="bubbleLeft"
                content="Ok, I'm convinced. Why are you a perfect partner for me?"
                secondary
              />
            </Col>
            <Col>
              <BubbleSpeach
                variant="bubbleRight"
                maxWidth="360px"
                backgroundColor={(props) => props.theme.greenLight2}
                content="Glad you asked!"
                secondary
              />
            </Col>
          </Row>
        </Section>
      </Container>
      <div style={chooseUs}>
        <Section id="about">
          <ChooseUs />
        </Section>
      </div>
      <Container>
        <Section>
          <TrustedBy />
        </Section>
        <Section>
          <Row>
            <Col>
              <BubbleSpeach
                variant="bubbleLeft"
                content="Tell me only one last thing: can you really help my business?"
                secondary
              />
            </Col>
            <Col>
              <BubbleSpeach
                variant="bubbleRight"
                maxWidth="360px"
                backgroundColor={(props) => props.theme.greenLight2}
                content="Yes, we can!"
                secondary
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{textAlign: 'center'}}>
                <button type="button" className="primary-btn btn-hcn" style={{fontSize: '20px'}} data-hcn="get-consultation">Get free consultation now!</button>
              </div>
            </Col>
          </Row>
        </Section>
      </Container>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        buttonWrapperClasses="button-wrapper"
      >
        Our website uses cookies to ensure you get the best, most relevant experience. By clicking the Accept button, you're agreeing to our use of cookies. To find out more please see our privacy policy.
      </CookieConsent>
    </Layout>
  )
}

IndexPage.propTypes = {
  theme: PropTypes.node
}
IndexPage.defaultProps = {
  theme: null
}

export default IndexPage
