import styled from 'styled-components'
import { Breakpoints } from '../../utils/grid-system/Breakpoints'

const BannerSectionWrapper = styled.section`
  /* padding: 120px 0 0 0; */
    background: ${(props) =>
      props.theme.green ? props.theme.green : '#000000'} !important;
  }
  /* background-size: 56% 82%; */
  /* background-position: top right; */

  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-20px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-20px);
    }
  }
  .hero-img{
      /* animation: bounce 1s infinite alternate; */
      /* -webkit-animation: bounce 1s infinite alternate; */
    margin: 0;
  }
  h1 {
    padding:  0 0 50px;
  }
  h1 span {
    font-size: 46px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) =>
      props.theme.black ? props.theme.black : '#000000'} !important;
  }
  h1 span.bold {
    font-weight: 800;
  }
  h1 span.accent {
    display: inline-block;
    min-width: 205px;
    text-align: centered;
    padding: 0 20px 5px 27px;
    margin-right: 10px;
    border-radius: 50px;
    box-shadow: 4px 5px 0 0 rgba(97, 205, 199, 0.2);
    background-color: #ffffff;
    @media only screen and (min-width: ${Breakpoints.lg}) {
      min-width: 320px;
      text-align: centered;
    }
  }
  button {
    color: ${(props) =>
      props.theme.white ? props.theme.white : '#000000'} !important;
    background: ${(props) =>
      props.theme.greenLight1 ? props.theme.greenLight1 : '#000000'} !important;
    border-radius: 50%;
    border: none;
    width: 76px;
    height: 76px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 60px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) =>
      props.theme.black ? props.theme.black : '#000000'} !important;
  }

  @media only screen and (max-width: ${Breakpoints.lg}) {
    h1 {
      font-size: 48px;
      line-height: 1.04em;
    }
    h1 span {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: ${Breakpoints.md}) {
    /* padding: 130px 0 60px 0; */
    h1, .hero-img {
      margin-top: 40px;
  }
  }
  @media only screen and (max-width: ${Breakpoints.sm}) {
    padding-top: 12px;
    background: none;
    h1 {
      text-align: center;
    }
    .hero-img {
      margin-top: 0;
    }
    h1 {
      margin-top: 40px;
    }
    span.subtitle {
      margin: 0;
    }
    button {
      width: 55px;
      height: 55px;
      display: block;
      margin: 20px auto;
  }
  }
  @media only screen and (max-width: 480px) {
    /* padding: 120px 0 100px 0; */
    .vintage-box {
      &:before {
        content: none;
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .banner-btn {
      font-size: 14px;
      min-width: 127px;
      padding: 14px 12px;
    }
    .banner-btn + .banner-btn {
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 330px) {
  }
`

export default BannerSectionWrapper
