import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

const SEO = ({ description, lang, title, keywords, noindex, structuredData }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author,
            image,
            siteUrl
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
    >
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0"
      />
      <meta name="description" content={description || site.siteMetadata.description} />
      <meta name="keywords" content={keywords || site.siteMetadata.keywords} />
      <title>{title || site.siteMetadata.title}</title>
      <html lang={lang} />
      {noindex ? <meta name="robots" CONTENT="noindex"/> : ''}
      {structuredData ? <script type="application/ld+json">{ `{ ${structuredData} }` }</script> : ''}

      {/* Google / Search Engine Meta Tags */}
      <meta itemProp="name" content={site.siteMetadata.author} />
      <meta itemProp="image" content={site.siteMetadata.image} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || site.siteMetadata.title} />
      <meta
        property="og:description"
        content={description || site.siteMetadata.description}
      />
      <meta property="og:image" content={site.siteMetadata.image} />
      <meta property="og:image:type" content="image/jpg" />
      {/* <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:creator"
        content={site.siteMetadata.twitterUsername}
      />
      <meta name="twitter:title" content={title || site.siteMetadata.title} />
      <meta
        name="twitter:description"
        content={description || site.siteMetadata.description}
      />
      <meta name="twitter:image" content={site.siteMetadata.image} /> */}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'el',
  description: ''
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  noindex: PropTypes.bool,
  structuredData: PropTypes.string
};

export default SEO;
