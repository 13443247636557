import styled from 'styled-components'
import { Breakpoints } from '../../utils/grid-system/Breakpoints'

const BubbleSpeachWrapper = styled.div`
  &.secondary {
    max-width: 480px;
    width: auto;
    margin: 0 auto;
    h2, h3 {
      font-size: 24px;
    }
     h2 {
       color: #5C5C5C;
        text-align: left;
     } 
     h3 {
       color: #354063;
     }
  }
  .bubbleLeft {
    padding: 30px;
    font-size: 28px;
    @media only screen and (min-width: ${Breakpoints.xl}) {
      font-size: 40px;
  }
  }
  .bubbleRight {
    padding: 15px 30px;
    font-size: 20px;
    @media only screen and (min-width: ${Breakpoints.xl}) {
      font-size: 24px;
    }
  }
  h2, h3 {
    display: block;
    position: relative;
    max-width: ${(props) =>
      props.maxWidth ? props.maxWidth : '800px'} !important;
    margin: 10px auto;
    border-radius: 100px;
    background: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.gray} !important;
    color: ${(props) =>
      props.color ? props.color : props.theme.blackLight1} !important;
    font-weight: 800;
    text-align: center;
    line-height: normal;
    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 40px;
      position: absolute;
      top: 80%;
      border-radius: 50%;
    }
  }
  h3 {
    margin-bottom: 55px;
    @media only screen and (min-width: ${Breakpoints.lg}) {
      margin-bottom: 90px;
    }
  }
  .bubbleLeft::after {
    left: 0;
    box-shadow: 25px 1px 0 0
      ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.gray} !important;
  }
  .bubbleRight::after {
    right: 0;
    box-shadow: -25px -1px 0 0 ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.gray)} !important;
  }
`
export default BubbleSpeachWrapper
