import styled from 'styled-components'
import { Breakpoints } from '../../utils/grid-system/Breakpoints'

const StyledSolutionCard = styled.div`
  text-align: center;
  flex: 1;
  background: ${(props) =>
    props.background ? props.background : props.theme.blueLight2} !important;
  border-radius: 20px;
  padding: 85px 15px 50px;
  margin: 75px auto 60px;
  position: relative;
      
  @media only screen and (min-width: ${Breakpoints.xl}) {
    padding: 98px 32px 70px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    width: 400px;
  }    

  img {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%) translateY(65%);
    width: 126px;
    padding: 0;
    margin: 0;
  }
  h4 {
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) =>
      props.headerColor
        ? props.headerColor
        : props.theme.blackLight1} !important;
  }
  p {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: ${(props) => props.theme.blackLight2};
    @media only screen and (max-width: 1199px) {
      font-size: 26px;
    }
  }
  span {
    display: inline-block;
    border-radius: 20px;
    box-shadow: 6px 6px 0 0
      ${(props) =>
        props.valueShadow
          ? props.valueShadow
          : props.theme.blueLight3} !important;
    background: ${(props) =>
      props.valueBackgroundColor
        ? props.valueBackgroundColor
        : props.theme.blueLight1} !important;
    margin: 0 11.4px 0 7px;
    font-size: 29px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) =>
      props.valueColor ? props.valueColor : props.theme.white} !important;
    padding: 0 20px 0 15px;


    @media only screen and (min-width: ${Breakpoints.xl}) {
      font-size: 32px;
    }
  }
`
export default StyledSolutionCard
