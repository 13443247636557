import CheckImg from '../images/solutions/check@2x.png'
import ClapImg from '../images/solutions/clap@2x.png'
import ThumbImg from '../images/solutions/thumb@2x.png'

const Data = [
  {
  background: (props) => props.theme.blueLight2,
  icon: ThumbImg,
  iconBackground: (props) => props.theme.blueLight1,
  header: 'Employee Experience',
  headerColor: (props) => props.theme.blackLight1,
  bodyBefore: 'Give superpowers to your',
  value: 'HR',
  valueColor: (props) => props.theme.white,
  valueBackgroundColor: (props) => props.theme.blueLight2,
  valueShadow: (props) => props.theme.blueLight3,
  bodyAfter: ''
  },
  {
  background: (props) => props.theme.brownLight2,
  icon: ClapImg,
  iconBackground: (props) => props.theme.brownLight1,
  header: 'Account Experience',
  headerColor: (props) => props.theme.brownLight4,
  bodyBefore: 'Upgrade your',
  value: 'Account',
  valueColor: (props) => props.theme.white,
  valueBackgroundColor: (props) => props.theme.brownLight3,
  valueShadow: (props) => props.theme.brownLight4,
  bodyAfter: 'OPs'
  },
  {
  background: (props) => props.theme.greenLight2,
  icon: CheckImg,
  iconBackground: (props) => props.theme.greenLight3,
  header: 'Lead Experience',
  headerColor: (props) => props.theme.greenLight1,
  bodyBefore: 'Transform your',
  value: 'Sales',
  valueColor: (props) => props.theme.greenLight1,
  valueBackgroundColor: (props) => props.theme.green,
  valueShadow: (props) => props.theme.blueLight3,
  bodyAfter: ''
  }
]

export default Data
