const Data = [
  {
    name: 'Solutions',
    path: 'solutions',
    offset: '-50'
  },
  {
    name: 'Automations',
    path: 'automations',
    offset: '-50'
  },
  {
    name: 'About',
    path: 'about',
    offset: '-50'
  },
  {
    name: 'Get free consultation',
    path: '/cta',
    offset: '-50',
    cta: true,
    data: {
      'data-hcn': 'get-consultation'
    },
    customClass: 'cta btn-hcn'
  }
]

export default Data
