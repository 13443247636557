import React from 'react'
import {
  StyledIntegrationsWrapper,
  StyledIntegrationLogoGrid,
  StyledIntegrationLogo
} from './integrations.style'
import teams from '../../assets/images/integrations/teams@2x.png'
import messenger from '../../assets/images/integrations/messenger@2x.png'
import whatsapp from '../../assets/images/integrations/whatsapp@2x.png'
import viber from '../../assets/images/integrations/viber@2x.png'
import skype from '../../assets/images/integrations/skype@2x.png'
import telegram from '../../assets/images/integrations/telegram@2x.png'
import sway from '../../assets/images/integrations/sway@2x.png'
import office365 from '../../assets/images/integrations/office365@2x.png'
import salesforce from '../../assets/images/integrations/salesforce@2x.png'
// import android from '../../assets/images/integrations/android@2x.png'
import android from '../../assets/images/integrations/android_new@2x.png'
import ios from '../../assets/images/integrations/ios@2x.png'

const logosStructure = {
  desktop: {
    firstRow: [
      teams,
      messenger,
      whatsapp,
      viber,
      skype
    ],
    secondRow: [
      telegram,
      sway,
      office365,
      salesforce
    ],
    thirdRow: [
      android,
      ios
    ]
  },
  mobile: {
    firstRow: [
      teams,
      messenger
    ],
    secondRow: [
      whatsapp,
      viber,
      skype
    ],
    thirdRow: [
      telegram,
      sway,
      office365,
      salesforce
    ],
    fifthRow: [
      android,
      ios
    ]
  }
}
const Integrations = () => (
  <StyledIntegrationsWrapper>
    <p className="text-center">
      Integrate with all the communication & business platforms, together with
      RPA frameworks.
    </p>
    <div className="display-xs-none display-md-block">
      { logosStructure.desktop
        ? (
          Object.keys(logosStructure.desktop).map((row, index) => (
            <StyledIntegrationLogoGrid key={`row_${index.toString()}`}>
              {logosStructure.desktop[row].map(logo => <StyledIntegrationLogo src={logo} key={logo} />)}
            </StyledIntegrationLogoGrid>
          ))
        )
        : null }
    </div>
    <div className="display-xs-block display-md-none">
      { logosStructure.mobile
        ? (
          Object.keys(logosStructure.mobile).map((row, index) => (
            <StyledIntegrationLogoGrid key={`row_${index.toString()}`}>
              {logosStructure.mobile[row].map(logo => <StyledIntegrationLogo src={logo} key={logo} />)}
            </StyledIntegrationLogoGrid>
          ))
        )
        : null }
    </div>
  </StyledIntegrationsWrapper>
)

export default Integrations
