import React from 'react'
import PropTypes from 'prop-types'
import ReactRotatingText from 'react-rotating-text'

import styled from 'styled-components'

const RotatingTextStyle = styled.span`
  .react-rotating-text-cursor {
    visibility: hidden;
    position: relative;
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;

    &::after {
      visibility: visible;
      // position: absolute;
      bottom: 0;
      font-size: 35px;
      left: 0;
      margin-left:-6px;
      content: "_";
      animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
    }
  }
  
  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const RotatingText = ({ text }) => (
  <RotatingTextStyle>
    <ReactRotatingText
      items={text}
      emptyPause={250}
      pause={3000}
      typingInterval={70}
    />
  </RotatingTextStyle>
)
RotatingText.propTypes = {
  text: PropTypes.arrayOf(PropTypes.string)
}
RotatingText.defaultProps = {
  text: []
}

export default RotatingText
