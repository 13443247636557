import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledAutomationsWrapper,
  StyledAutomationsItemWrapper,
  StyledAutomationsItem
} from './automations.style'
import defaultImage from '../../assets/images/automations-robot@2x.png'
import mobileRobotWCircles from '../../assets/images/robot_with_circles@2x.png'
import gray from '../../assets/images/blocks/gray-block.svg'
import purple from '../../assets/images/blocks/purple-block.svg'
import orange from '../../assets/images/blocks/orange-block.svg'
import green from '../../assets/images/blocks/green-block.svg'
import blue from '../../assets/images/blocks/blue-block.svg'
import red from '../../assets/images/blocks/red-block.svg'
import yellow from '../../assets/images/blocks/yellow-block.svg'
import turquoise from '../../assets/images/blocks/turquoise-block.svg'

const blocks = [gray, purple, orange, green, blue, red, yellow, turquoise]
const Automations = ({ content, margin }) => (
  <StyledAutomationsWrapper margin={margin}>
    <div className="outer-1">
      <div className="outer-2">
        <img className="display-lg-block display-xs-none" src={defaultImage} alt="helvia value icon" />
        <img className="display-lg-none" src={mobileRobotWCircles} alt="helvia value icon" />
        <StyledAutomationsItemWrapper content={content}>
          {content && content.length > 0
            ? content.map((item) => (
              <StyledAutomationsItem position={item.position} key={item.position}>
                <img
                  src={blocks[item.position - 1]}
                  alt={`${item.block} automation block`}
                />
                <h4>{item.header}</h4>
              </StyledAutomationsItem>
            ))
            : null}
        </StyledAutomationsItemWrapper>
      </div>
    </div>
  </StyledAutomationsWrapper>
)

Automations.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  margin: PropTypes.string
}
Automations.defaultProps = {
  content: null,
  margin: ''
}
export default Automations
