import React from 'react'
// import { FaPlay } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../utils/grid-system'
import RotatingText from '../RotatingText'
// import SectionTitle from '../../reusecore/SectionTitle'
// import Button from '../../reusecore/Button'
// import VintageBox from '../../reusecore/VintageBox'
import BannerSectionWrapper from './banner.style'

const BannerModern = ({
  navbar,
  bold,
  title,
  accent,
  paragraph,
  // button,
  image
  // convNode
}) => (
  <BannerSectionWrapper>
    {navbar}
    <Container fullWidthSM>
      <Row Vcenter>
        <Col lg={8} sm={7} md={6}>
          {/* <SectionTitle */}
          {/*   className="section-title" */}
          {/*   leftAlign */}
          {/*   UniWidth="100%" */}
          {/* > */}
          {/* <h4>Αμεση εγκατασταση σε websites και mobile apps</h4> */}
          <h1>
            <span className="bold">
              {bold}
              &nbsp;
            </span>
            <span className="subtitle">
              {title}
              &nbsp;
            </span>
            <span className="accent">
              <RotatingText text={accent} />
              &nbsp;
            </span>
            <span className="subtitle">{paragraph}</span>
          </h1>
          {/* <span>{accent}</span> */}
          {/* </SectionTitle> */}
          {/* <button type="button">&gt;</button> */}
          {/* <VintageBox right vintageOne> */}
          {/*   <Button className="banner-btn one btn-hcn" data-hcn={convNode}> */}
          {/*     <FaPlay className="icon-left" /> */}
          {/*     {button} */}
          {/*   </Button> */}
          {/* </VintageBox> */}
        </Col>
        <Col display="flex" alignSelf="flex-end" lg={4} sm={5} md={6}>
          <img src={image} className="hero-img" alt="prime-app hero" />
        </Col>
      </Row>
    </Container>
  </BannerSectionWrapper>
)

BannerModern.propTypes = {
  navbar: PropTypes.node,
  bold: PropTypes.string,
  title: PropTypes.string,
  accent: PropTypes.arrayOf(PropTypes.string),
  paragraph: PropTypes.string,
  image: PropTypes.string
}
BannerModern.defaultProps = {
  navbar: null,
  bold: '',
  title: '',
  accent: [],
  paragraph: '',
  image: ''
}
export default BannerModern
