import React from 'react'
import PropTypes from 'prop-types'
import BubbleSpeachWrapper from './bubbleSpeach.style'

const BubbleSpeach = ({
  content,
  backgroundColor,
  fontSize,
  variant,
  maxWidth,
  secondary
}) => (
  <BubbleSpeachWrapper
    backgroundColor={backgroundColor}
    fontSize={fontSize}
    maxWidth={maxWidth}
    className={`${secondary ? 'secondary' : ''}`}
  >
    {
      variant === 'bubbleLeft'
        ? <h2 className={variant}>{content}</h2>
        : <h3 className={variant}>{content}</h3>
    }
  </BubbleSpeachWrapper>
)

BubbleSpeach.propTypes = {
  content: PropTypes.string,
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  fontSize: PropTypes.string,
  variant: PropTypes.oneOf(['bubbleLeft', 'bubbleRight']),
  maxWidth: PropTypes.string,
  secondary: PropTypes.bool
}
BubbleSpeach.defaultProps = {
  content: 'Helvia is awesome',
  backgroundColor: (props) => props.theme.gray,
  fontSize: '40px',
  variant: 'bubbleLeft',
  maxWidth: '800px',
  secondary: false
}
export default BubbleSpeach
