export const WhyUs = [
  {
    icon: 0,
    heading: 'Experience & subject matter expertise',
    body: 'Our thorough technological background, immerse experience and deep understanding of current trends and techniques ensure you will get truly valuable solutions for your needs.'
  },
  {
    icon: 1,
    heading: 'True partnership',
    body: 'We value our partners and we are dedicated to offering you great service, training according to your needs and unmatched support, and all this with honest pricing.'
  },
  {
    icon: 2,
    heading: 'Agile in every way',
    body: 'We are passionate with what we do and we understand what it takes to get great results. We work with agile methodologies, ensuring we adjust according to the requirements throughout the project.'
  }
]

export const Solutions = [
  {
    icon: 'engagement',
    heading: 'Increase in employee engagement'
  },
  {
    icon: 'openess',
    heading: 'Openness & breaking silos'
  },
  {
    icon: 'seamless',
    heading: 'Seamless integrations'
  },
  {
    icon: 'efficiency',
    heading: 'Improved efficiencies & organizational productivity'
  },
  {
    icon: 'faster',
    heading: 'Faster & more effective communications'
  }
]
