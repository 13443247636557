// todo: Not happy with this entire impelementation
import React from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../utils/grid-system/Breakpoints';

import eviol from '../../assets/images/clients/Eviol.jpg';
import kelly from '../../assets/images/clients/kelly.png';
import pobuca from '../../assets/images/clients/pobuca.png';
import safesize from '../../assets/images/clients/safesize.png';
import anotherCircus from '../../assets/images/clients/another-circus.png';
import bbdo from '../../assets/images/clients/bbdo.png';
import channelvas from '../../assets/images/clients/channelvas.jpg';

const safesizeData = {
  icon: safesize,
  link: 'https://www.safesize.com/'
};
const schannelvasData = {
  icon: channelvas,
  link: 'https://channelvas.com/'
};
const pobucaData = {
  icon: pobuca,
  link: 'https://www.pobuca.com/'
};
const kellyData = {
  icon: kelly,
  link: 'https://www.kellyservices.com/'
};
const bbdoData = {
  icon: bbdo,
  link: 'https://bbdo.com/'
};
const anotherCircusData = {
  icon: anotherCircus,
  link: 'https://www.anothercircus.com/'
};
const eviolData = {
  icon: eviol,
  link: 'https://www.eviol.gr/en/'
};

const leftIcons = [safesizeData, schannelvasData]
const middleIcons = [pobucaData, kellyData, bbdoData]
const rightIcons = [anotherCircusData, eviolData]

const mobileLeftIcons = [safesizeData, schannelvasData, pobucaData]
const mobileRightIcons = [kellyData, bbdoData, anotherCircusData, eviolData]

const TrustedByWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  margin: auto;
  padding-top: ${(props) =>
    props.paddingTop ? props.paddingTop : '40px'} !important;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '40px'} !important;
  
  h2 {
    font-size: 48px;
    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 54px;
    }
  }

  @media only screen and (max-width: ${Breakpoints.md}) {
    .desktop {
      display: none;
    }
  }
  @media only screen and (min-width: ${Breakpoints.md}) {
    .mobile {
      display: none;
    }
  }
`
export const TrustedByIconsGrid = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TrustedByIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
`
export const TrustedByIcon = styled.img`
  max-width: 200px;
  margin: 0 0 30px;
  padding: 32px 37px 32px 38px;
  border-radius: 16px;
  box-shadow: 0 32px 64px 0 rgba(17, 17, 17, 0.08);
  background-color: #ffffff;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    max-width: 150px;
  }
`

const TrustedBy = ({ ...props }) => {

  const renderLogos = (iconsData) => (
    iconsData && iconsData.length > 0
      ? iconsData.map((item) => (
        <a href={item.link} key={item.link} target="_blank" rel="noreferrer">
          <TrustedByIcon src={item.icon} alt="" />
        </a>
        ))
      : null
  );

  return (
    <TrustedByWrapper {...props}>
      <h2>We are trusted by</h2>
      <TrustedByIconsGrid className="display-xs-none display-md-flex">
        <TrustedByIconsWrapper>
          {renderLogos(leftIcons)}
        </TrustedByIconsWrapper>

        <TrustedByIconsWrapper>
          {renderLogos(middleIcons)}
        </TrustedByIconsWrapper>

        <TrustedByIconsWrapper>
          {renderLogos(rightIcons)}
        </TrustedByIconsWrapper>
      </TrustedByIconsGrid>

      <TrustedByIconsGrid className="display-xs-flex display-md-none">
        <TrustedByIconsWrapper>
          {renderLogos(mobileLeftIcons)}
        </TrustedByIconsWrapper>

        <TrustedByIconsWrapper>
          {renderLogos(mobileRightIcons)}
        </TrustedByIconsWrapper>
      </TrustedByIconsGrid>
    </TrustedByWrapper>
  )
}

export default TrustedBy
