import React from 'react'
import PropTypes from 'prop-types'
import StyledSolutionCard from './solutionCard.style'
import defaultIcon from '../../assets/images/solutions/faster-hire-icon.svg'

const SolutionCard = ({
  background,
  icon,
  iconBackground,
  headerColor,
  valueBackgroundColor,
  valueShadow,
  header,
  bodyBefore,
  value,
  valueColor,
  bodyAfter
}) => (
  <StyledSolutionCard
    background={background}
    valueBackgroundColor={valueBackgroundColor}
    valueColor={valueColor}
    valueShadow={valueShadow}
    headerColor={headerColor}
    iconBackground={iconBackground}
  >
    <img src={icon} alt="helvia value icon" />
    <h4>{header}</h4>
    <p>
      {bodyBefore}
      <span>
        {' '}
        {value}
        {' '}
      </span>
      {bodyAfter}
    </p>
  </StyledSolutionCard>
)

SolutionCard.propTypes = {
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.string,
  iconBackground: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  header: PropTypes.string,
  headerColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  bodyBefore: PropTypes.string,
  value: PropTypes.string,
  valueColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  valueBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  valueShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  bodyAfter: PropTypes.string
}
SolutionCard.defaultProps = {
  background: (props) => props.theme.blueLight2,
  icon: defaultIcon,
  iconBackground: (props) => props.theme.blueLight1,
  header: 'Value Header',
  headerColor: (props) => props.theme.blackLight1,
  bodyBefore: 'Before value body',
  value: 'VALUE',
  valueColor: (props) => props.theme.white,
  valueBackgroundColor: (props) => props.theme.blueLight2,
  valueShadow: (props) => props.theme.blueLight3,
  bodyAfter: ''
}
export default SolutionCard
