import styled from 'styled-components';
import { Breakpoints } from '../../utils/grid-system/Breakpoints';

export const StyledAutomationsWrapper = styled.div`
  margin: ${(props) => props.margin ? props.margin : '0 auto'} !important;
  @media only screen and (min-width: ${Breakpoints.lg}) {
    margin-top: 100px !important;
  }
  img {
    margin: -30px auto 0;
    max-height: 350px;
    
    @media only screen and (min-width: ${Breakpoints.lg}) {
      margin-top: 60px;
    }
    @media only screen and (max-width: 499px) {
      margin-top: -20px;
    }
  }
  .outer-1,
  .outer-2 {
    position: relative;
    border: 0 none;
    @media only screen and (min-width: ${Breakpoints.lg}) {
      border: 4px solid ${(props) => props.theme.green};
      border-radius: 100%;
    }
  }
  .outer-1 {
    padding: 25px;
    @media only screen and (min-width: ${Breakpoints.lg}) {
      padding: 125px;
    }
    @media only screen and (max-width: 499px) {
      padding: 10px;
    }
  }
  .outer-2 {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 350px;
    height: auto;
    
    @media only screen and (min-width: ${Breakpoints.lg}) {
      width: 500px;
      height: 500px;
    }
    @media only screen and (max-width: 499px) {
      width: 300px;
    }
  }
`

const Top = {
  lg: [
    '',
    '-37%',
    '-27%',
    '-18%',
    '-6%',
    '-4%',
    '-77%',
    '-168%',
    '-250%'
  ],
  xl: [
    '',
    '-37%',
    '-27%',
    '-18%',
    '-6%',
    '-15%',
    '-79%',
    '-181%',
    '-272%'
  ]
}
const Left = {
  lg: [
    '',
    '38%',
    '63%',
    '100%',
    '89%',
    '30%',
    '-44%',
    '-50%',
    '-30%'
  ],
  xl: [
    '',
    '38%',
    '63%',
    '113%',
    '105%',
    '39%',
    '-44%',
    '-64%',
    '-37%'
  ]
}

export const StyledAutomationsItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-self: center;
  margin-top: -125px;

  @media only screen and (min-width: ${Breakpoints.lg}) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -5%;
    margin-bottom: 250px;
  }
  @media only screen and (min-width: ${Breakpoints.xl}) {
    margin-bottom: 250px;
  }

  @media only screen and (max-width: 499px) {
    margin-top: -100px;
  }
`
export const StyledAutomationsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 7px 7px 50px 0 rgba(0, 0, 0, 0.1);

  justify-self: center;
  padding: 15px 10px 30px 10px;
  width: 158px;
  img {
    width: 44px;
    height: 30px;
  }

  img,
  h4 {
    margin: 0;
  }
  h4 {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  
  @media only screen and (min-width: ${Breakpoints.lg}) {
    display: inline-block;
    padding: 32px;
    position: relative;
    justify-self: auto;
    width: auto;
    img {
      width: auto;
      height: auto;
    }
    h4 {
      margin-top: 32px;
      max-width: 220px;
      text-align: left;
      font-size: 18px;
    }
    top: ${({ position }) =>
      (position === 1 && `${Top.lg[1]}`)
      || (position === 2 && `${Top.lg[2]}`)
      || (position === 3 && `${Top.lg[3]}`)
      || (position === 4 && `${Top.lg[4]}`)
      || (position === 5 && `${Top.lg[5]}`)
      || (position === 6 && `${Top.lg[6]}`)
      || (position === 7 && `${Top.lg[7]}`)
      || (position === 8 && `${Top.lg[8]}`)};
    left: ${({ position }) =>
      (position === 1 && `${Left.lg[1]}`)
      || (position === 2 && `${Left.lg[2]}`)
      || (position === 3 && `${Left.lg[3]}`)
      || (position === 4 && `${Left.lg[4]}`)
      || (position === 5 && `${Left.lg[5]}`)
      || (position === 6 && `${Left.lg[6]}`)
      || (position === 7 && `${Left.lg[7]}`)
      || (position === 8 && `${Left.lg[8]}`)};
  }
  @media only screen and (min-width: ${Breakpoints.xl}) {
    h4 {
      font-size: 24px;
    }
    top: ${({ position }) =>
      (position === 1 && `${Top.xl[1]}`)
      || (position === 2 && `${Top.xl[2]}`)
      || (position === 3 && `${Top.xl[3]}`)
      || (position === 4 && `${Top.xl[4]}`)
      || (position === 5 && `${Top.xl[5]}`)
      || (position === 6 && `${Top.xl[6]}`)
      || (position === 7 && `${Top.xl[7]}`)
      || (position === 8 && `${Top.xl[8]}`)};
    left: ${({ position }) =>
      (position === 1 && `${Left.xl[1]}`)
      || (position === 2 && `${Left.xl[2]}`)
      || (position === 3 && `${Left.xl[3]}`)
      || (position === 4 && `${Left.xl[4]}`)
      || (position === 5 && `${Left.xl[5]}`)
      || (position === 6 && `${Left.xl[6]}`)
      || (position === 7 && `${Left.xl[7]}`)
      || (position === 8 && `${Left.xl[8]}`)};
  }
  }
`
