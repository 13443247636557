import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { smoothScrollTo } from '../../../utils'

const ScrollspyMenu = ({ menuItems, className}) => {
  const addAllClasses = ['']
  if (className) {
    addAllClasses.push(className)
  }
  const generateAncor = (item) => {
    const dataAttr = item.data ? item.data : '';
    return (
      <a 
        href="#"
        onClick={
          () => smoothScrollTo(item.path)
        } 
        className={
          item.customClass ? item.customClass : ''
        } 
        title={item.name}
        {...dataAttr}
        >
          {item.name}
        </a>
    )
  }

  return (
    <ul className={addAllClasses.join(' ')}>
      {menuItems.map((menu) => (
        <li
          key={menu.name}
          className={
            menu.subItems !== undefined ? 'nav-item has-dropdown' : 'nav-item'
          }
        >
          {menu.link !== undefined ? (
            <a href={menu.link}>{menu.name}</a>
          ) : (
            generateAncor(menu)
          )}
          {menu.subItems !== undefined && (
            <ul key={menu.name} className="dropdown">
              {menu.subItems.map((subItem) => (
                <li key={subItem.name}>
                  <Link to={subItem.path} partiallyActive>
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  )
}

ScrollspyMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
}
ScrollspyMenu.defaultProps = {
  menuItems: null,
  className: ''
}
export default ScrollspyMenu
