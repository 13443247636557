import React from 'react'
import styled from 'styled-components'
import { Breakpoints } from '../../utils/grid-system/Breakpoints'

import { WhyUs, Solutions } from '../../assets/content/chooseUs'
import experience from '../../assets/images/chooseUs/experience.svg'
import partnership from '../../assets/images/chooseUs/partnership.svg'
import agile from '../../assets/images/chooseUs/agile.svg'

import engagement from '../../assets/images/chooseUs/engagement.svg'
import openess from '../../assets/images/chooseUs/openess.svg'
import seamless from '../../assets/images/chooseUs/seamless.svg'
import efficiency from '../../assets/images/chooseUs/efficiency.svg'
import faster from '../../assets/images/chooseUs/faster.svg'

const WhyUsIcons = [experience, partnership, agile]
const SolutionIcons = [engagement, openess, seamless, efficiency, faster]

const ChooseUsWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  margin: auto;
  h2 {
    font-size: 28px;
    @media only screen and (min-width: ${Breakpoints.lg}) {
      font-size: 40px;
    }
  }
  .why-us-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1090px;
    margin: auto;
    justify-content: center;
    padding-bottom: 25px;
    &.last-child {
      padding-bottom: 0;
    }
  }
  padding-top: ${(props) =>
    props.paddingTop ? props.paddingTop : '0'} !important;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '0'} !important;
`
const ChooseUsValues = styled.div`
  max-width: 350px;
  padding: 0 15px;
  h4 {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.75px;
    text-align: left;
    color: #18191f;
  }
  p {
    font-size: 18px;
    text-align: left;
  }
  @media only screen and (max-width: ${Breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4 {
      font-size: 20px;
    }
    h4, p {
      text-align: center;
    }
    p {
      font-size: 14px;
    }
    img {
      margin: auto;
  }
  }
`
const ChooseUsSolutions = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  //gap: 10px;
  img {
    margin-right: 10px;
  }
  
  h4 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.75px;
    text-align: left;
    color: #18191f;
      
    @media only screen and (min-width: ${Breakpoints.lg}) {
      font-size: 24px;
    }
  }
`

const ChooseUs = ({ ...props }) => (
  <ChooseUsWrapper {...props}>
    <h2>Why choose Helvia</h2>
    <div className="why-us-section">
      {WhyUs && WhyUs.length > 0
        ? WhyUs.map((item, index) => (
          <ChooseUsValues key={item.heading}>
            <img src={WhyUsIcons[index]} alt="" />
            <h4>{item.heading}</h4>
            <p>{item.body}</p>
          </ChooseUsValues>
          ))
        : null}
    </div>
    <h2>Our solutions help you benefit from</h2>
    <div className="why-us-section last-child">
      {Solutions && Solutions.length > 0
        ? Solutions.map((item, index) => (
          <ChooseUsSolutions key={item.heading}>
            <img src={SolutionIcons[index]} alt="" />
            <h4>{item.heading}</h4>
          </ChooseUsSolutions>
          ))
        : null}
    </div>
  </ChooseUsWrapper>
)

export default ChooseUs
