import styled from 'styled-components'
import { Breakpoints } from '../../utils/grid-system/Breakpoints'

// todo: I am not happy about this component... it needs rethinking for a better implementation. Reason? Lot's of fixed (pixel) widths
export const StyledIntegrationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    max-width: 540px;
  }
`
export const StyledIntegrationLogoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  padding: 4px 0;
  max-width: 1065px;
  
  @media only screen and (min-width: ${Breakpoints.md}) {
    padding: 8px 0;
  }
  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 15px 0;
  }
`
export const StyledIntegrationLogo = styled.img`
  max-width: 69px;
  margin: 0 4px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 32px 64px 0 rgba(17, 17, 17, 0.08);
  background-color: ${(props) => props.theme.white};

  @media only screen and (min-width: ${Breakpoints.sm}) {
    max-width: 100px;
    padding: 10pxgit;
  }
  @media only screen and (min-width: ${Breakpoints.md}) {
    margin: 0 8px;
  }
  @media only screen and (min-width: ${Breakpoints.lg}) {
    max-width: 150px;
    margin: 0 15px;
    padding: 28px 32px;
  }
`
